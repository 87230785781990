const formElements = document.querySelectorAll(".form");

formElements.forEach((_element) => {
  const formElement: HTMLFormElement = _element.querySelector(
    "form"
  ) as HTMLFormElement;
  const doneElement: HTMLElement = _element.querySelector(
    ".form-done"
  ) as HTMLElement;
  const failElement: HTMLElement = _element.querySelector(
    ".form-fail"
  ) as HTMLElement;

  formElement?.addEventListener("submit", async (e) => {
    /**
     * Prevent the default browser behaviour of submitting
     * the form so that you can handle this instead.
     */
    e.preventDefault();

    /**
     * Get the element attached to the event handler.
     */
    let form: HTMLFormElement = e.currentTarget as HTMLFormElement;

    /**
     * Take the URL from the form's `action` attribute.
     */
    let url = form.action;

    try {
      /**
       * Takes all the form fields and make the field values
       * available through a `FormData` instance.
       */
      let formData = new FormData(form);

      /**
       * The `postFormFieldsAsJson()` function in the next step.
       */
      let responseData = await submitFormJSON({ url, formData });

      // Destructure the response data
      let { serverDataResponse } = responseData;

      // Display the response data in the console (for debugging)
      console.log(serverDataResponse);

      doneElement.style.display = "block";
      failElement.style.display = "none";
      formElement.style.display = "none";
    } catch (error) {
      // If an error occurs display it in the console (for debugging)
      console.error(error);

      doneElement.style.display = "none";
      failElement.style.display = "block";
      formElement.style.display = "block";
    }
  });
});

/**
 * Helper function to serialize FormData into an array
 */
function serializeFormData(formData: FormData) {
  // Create an array to hold the field index/name/value
  const items = new Array();

  // Add each name/value pair to the array
  for (const [name, value] of formData as any) {
    items.push({
      objectTypeId: "0-1",
      name: name,
      value: value,
    });
  }

  // Return the array
  return items;
}

/**
 * Helper function to POST data as JSON with Fetch.
 */
async function submitFormJSON({ url, formData }) {
  // Format the plain form data as JSON
  let formDataJsonString = JSON.stringify({
    fields: serializeFormData(formData),
  });

  //Set the fetch options (headers, body)
  let fetchOptions = {
    //HTTP method set to POST.
    method: "POST",
    //Set the headers that specify you're sending a JSON body request and accepting JSON response
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    // POST request body as JSON string.
    body: formDataJsonString,
  };

  //Get the response body as JSON.
  //If the response was not OK, throw an error.
  let res = await fetch(url, fetchOptions);

  //If the response is not ok throw an error (for debugging)
  if (!res.ok) {
    let error = await res.text();
    throw new Error(error);
  }
  //If the response was OK, return the response body.
  return res.json();
}
